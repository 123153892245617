import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import type { Context } from '@nuxt/types'
import { ISeat } from '~/services/types/ManageUsers'

interface IManageUsersService {
  $axios: NuxtAxiosInstance,
  checkPhoneNumber: (phoneNumber: string) => Promise<ISeat>,
  checkAreaCode: (areaCode: string) => Promise<ISeat>
}

export default class ManageUsersService implements IManageUsersService {
  $axios: NuxtAxiosInstance
  constructor (context: Context) {
    this.$axios = context.$axios
  }

  checkPhoneNumber (phoneNumber: string) {
    return this.$axios.$get('/api/check-number', { params: { phone: phoneNumber } }) as Promise<ISeat>
  }

  checkAreaCode (areaCode: string) {
    return this.$axios.$get('/api/check-area-code', { params: { area_code: areaCode } }) as Promise<ISeat>
  }
}
